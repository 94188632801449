import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Wrapper = styled.div`
    grid-column: 2 / span 4
`

const NotFound = () => {
    return(
        <Layout>
            <div className="container">
                <Wrapper>
                    <SEO title='Page not found' />
                    <h1>Page Not Found</h1>
                    <p><Link to='/'>Back to home</Link></p>
                </Wrapper>
            </div>
        </Layout>
    )
}

export default NotFound